<template>
  <v-container class="mt-6">
    <v-row>
      <v-col cols="12" class="d-">
        <h2 class="display-1 white--text full-width fw-1 text-center text-lg-left text-uppercase no-select">Le tue sedute in palestra</h2>
      </v-col>
      <v-col cols="12" class="pb-3" v-if="profile && profile.user_type === 'card' && noMedicalCert" @click="$router.push('/account')">
        <v-alert dense border="left" type="error">{{noMedicalCertMessage}}
          <v-btn text :small="$vuetify.breakpoint.smAndDown" @click="$router.push('/medical')">PRENOTA VISITA MEDICA</v-btn>
          <v-btn text :small="$vuetify.breakpoint.smAndDown" @click="$router.push('/account')">VEDI PROFILO</v-btn>
        </v-alert>
      </v-col>
      <v-col cols="12" v-if="profile && profile.user_notes">
        <h2 class="white--text text-uppercase no-select fw-4 mb-3">NOTE DA PARTE DI TOPGYM</h2>
        <p class="pre white--text">{{profile.user_notes}}</p>
      </v-col>
      <v-col cols="12" v-if="profile.scheda">
        <v-btn rounded color="accent" small :href="schedaUrl" >Scarica la tua scheda di allenamento</v-btn>
      </v-col>
      <v-col cols="12">
        <h2 class="white--text text-uppercase no-select fw-4 mb-3">I TUOI ABBONAMENTI ATTIVI</h2>

        <v-row>
          <v-col cols="12" v-if="!validUserSeats.length" class="white--text font-italic no-select">Al momento non hai nessun abbonamento attivo. <a class="text-decoration-underline" @click="$router.replace('/')">Acquista un pacchetto</a></v-col>
          <v-col cols="12" v-else>
            <v-btn small color="error" rounded v-if="!profile.pause_subscription" :disabled="!canPauseSubscription" @click="setSubscriptionPaused(true)">Metti in pausa i tuoi abbonamenti</v-btn>
            <v-btn small color="error" rounded v-if="profile.pause_subscription" @click="setSubscriptionPaused(false)">Riattiva gli abbonamenti in pausa</v-btn>
            <p class="no-select white--text mt-2 mb-1">Giorni di pausa usati nell'anno sportivo: {{profile.num_pauses}} di {{numDaysOfPausesAvailable}} a disposizione dal {{sportYearStart}} al {{sportYearEnd}}</p>
            <p class="no-select accent--text font-weight-bold text-decoration-underline text-uppercase"><a class="pointer" @click="openPauseDialog()">Vuoi dei giorni in più di pausa?</a></p>
          </v-col>
          <v-col cols="12">
            <v-card dark v-for="(seats, i) in validUserSeats" :key="`valid_useats_${i}_${seats._id}`" class="mb-3" :rounded="false">
              <v-card-text>
                <h4 v-if="getProductName(seats.product_id)" class="mb-1 no-select white--text">Pacchetto "{{getProductName(seats.product_id)}}" con accesso a sedute di tipo:</h4>
                <h4 v-else class="mb-1 no-select white--text">Pacchetto con accesso a sedute di tipo:</h4>
                <v-chip-group>
                  <v-chip small class="white--text font-weight-bold text-uppercase" v-for="(st, j) in seats.seats_types" :key="`valid_us_${seats._id}_st_${j}`" :color="getSeatsTypeColor(st)">{{getSeatsTypeLabel(st)}}</v-chip>
                </v-chip-group>
                <p v-if="seats.expiration" class="warning--text">Scadenza {{formatDate(seats.expiration)}}</p>
                <p class="warning--text no-select" v-if="seats.consumable && !seats.expiration">Durata di {{seats.duration}} giorni a partire dalla prima prenotazione.</p>
                <p class="success--text no-select" v-if="seats.consumable">Hai a disposizione {{seats.available - seats.used}} sedute.</p>
              </v-card-text>
            </v-card>

          </v-col>
          <!--
          <v-col cols="12" v-for="(seats, i) in validUserSeats" :key="`valid_us_${i}_${seats._id}`">
            <p class="mb-0 no-select white--text">Pacchetto con accesso a sedute di tipo:</p>
            <v-chip-group>
              <v-chip small class="white--text font-weight-bold text-uppercase" v-for="(st, j) in seats.seats_types" :key="`valid_us_${seats._id}_st_${j}`" :color="getSeatsTypeColor(st)">{{getSeatsTypeLabel(st)}}</v-chip>
            </v-chip-group>
            <p v-if="seats.expiration" class="warning--text">Scadenza {{formatDate(seats.expiration)}}</p>
            <p class="warning--text no-select" v-if="seats.consumable && !seats.expiration">Durata di {{seats.duration}} giorni a partire dalla prima prenotazione.</p>
            <p class="success--text no-select" v-if="seats.consumable">Hai a disposizione {{seats.available - seats.used}} sedute.</p>
          </v-col>-->
        </v-row>
      </v-col>

      <v-col cols="12" v-if="bookings && bookings.length"><v-divider dark></v-divider></v-col>
      <v-col cols="12" v-if="bookings && bookings.length">
        <h2 class="white--text text-uppercase no-select">Le tue prenotazioni</h2>
        <small class="white--text no-select">Se non puoi più partecipare ad una seduta ricordati di cancellare la prenotazione o altrimenti la perdi! Puoi farlo entro {{settings.cancel_within_minutes}} {{settings.cancel_within_minutes === 1 ? 'minuto' : 'minuti'}} dall'inizio della lezione.</small>
        <v-list-item class="white rounded mt-1" v-for="book in bookings" :key="book._id">
          <v-list-item-title>{{formatDateTime(book.date)}}</v-list-item-title>
          <v-list-item-subtitle>{{getLbl(book.calendar_hour.type)}}<span v-if="book.calendar_hour.type === 'corsi'">: {{book.calendar_hour.name}}</span></v-list-item-subtitle>
          <v-list-item-action><v-btn icon color="accent" @click="requestCancelBook(book)"><v-icon>mdi-close</v-icon></v-btn></v-list-item-action>
        </v-list-item>
      </v-col>


      <v-col cols="12" v-if="validUserSeats.length && !profile.pause_subscription">
        <v-row>
          <v-col cols="12"><v-divider dark></v-divider></v-col>
          <v-col cols="12">
            <h2 class="display-1 white--text full-width fw-1 text-center text-lg-left text-uppercase no-select">Prenota una seduta</h2>
          </v-col>
          <v-col cols="12">
            <h4 class="white--text text-uppercase no-select fw-4 mb-3">Seleziona il pacchetto che vuoi utilizzare:</h4>
            <v-select rounded filled dark :items="validUserSeatsOptions" label="Pacchetto abbonamento" v-model="userSeatsId" style="max-width: 600px">
              <template #item="{ item, on, attrs}">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <div >

                      <h5 v-if="getProductName(item.data.product_id)" class="no-select">Pacchetto "{{getProductName(item.data.product_id)}}" con accesso a sedute di tipo:</h5>
                      <h5 v-else class="no-select">Pacchetto con accesso a sedute di tipo:</h5>
                      <v-chip-group>
                        <v-chip x-small class="white--text font-weight-bold text-uppercase" v-for="(st, j) in item.data.seats_types" :key="`valid_listus_${item.data._id}_st_${j}`" :color="getSeatsTypeColor(st)">{{getSeatsTypeLabel(st)}}</v-chip>
                      </v-chip-group>
                      <p v-if="item.data.expiration" class="warning--text caption mb-0">Scadenza {{formatDate(item.data.expiration)}}.</p>
                      <p class="warning--text no-select caption mb-0" v-if="item.data.consumable && !item.data.expiration">Durata di {{item.data.duration}} giorni a partire dalla prima prenotazione.</p>
                      <p class="success--text no-select caption mb-0" v-if="item.data.consumable">{{item.data.available - item.data.used}} sedute disponibili.</p>

                    </div>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" v-if="userSeatsId">
            <h4 class="white--text text-uppercase no-select fw-4 mb-3">Seleziona la sala dove vuoi prenotare:</h4>
            <v-btn-toggle dark v-model="calendarType" borderless rounded background-color="transparent" mandatory>
              <v-btn dark v-for="opt in calendarTypeOpts" :key="'btn_'+opt.value" :value="opt.value" color="primary"
                     class="white--text font-weight-regular pl-2 pr-2 pl-md-3 pr-md-3 pl-lg-6 pr-lg-6"
                     active-class="font-weight-bold text-decoration-underline" :small="$vuetify.breakpoint.smAndDown">
                {{$vuetify.breakpoint.smAndDown ? opt.abbrev : opt.text}}
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <v-col cols="12" md="6" lg="4" v-if="calendarType" >
            <p class="white--text full-width text-uppercase no-select mb-2">Seleziona la data per <v-chip small class="white--text font-weight-bold" :color="getSeatsTypeColor(calendarType)">{{getSeatsTypeLabel(calendarType)}}</v-chip></p>
            <small class="error--text no-select">Ricordati che per prenotare una seduta lo puoi fare solo con un anticipo di almeno {{settings.booking_within_minutes}} {{settings.booking_within_minutes === 1 ? 'minuto' : 'minuti'}} rispetto alla seduta stessa!</small>
            <v-date-picker class="mt-4" full-width v-model="date" elevation="4" locale="it" :allowed-dates="allowedDates" :events="isBooked" event-color="info"/>
          </v-col>
          <v-col cols="12" md="6" lg="4" v-if="date">
            <p class="white--text full-width text-uppercase no-select ml-1">Seleziona l'orario</p>
            <p v-if="bookings.length >= 3" class="font-italic grey--text">Non puoi fare più di tre prenotazioni</p>
            <p v-else-if="isBooked(date)" class="font-italic grey--text">Non puoi fare due prenotazioni nello stesso giorno</p>
            <v-radio-group v-else v-model="selectedHour" @change="onSelectedHour">
              <v-radio
                  v-ripple
                  class="pa-3 white ma-1 rounded"
                  v-for="hour in hours" :key="hour._id"
                  :label="`${extendedDate} - ${hour.time}` + (hour.type === 'corsi' ? ` - ${hour.name}` : '')"
                  :value="hour._id"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="6" lg="4" v-if="date && selectedHour">
            <v-row v-if="currentPrefs.length" class="mb-3">
              <v-col cols="12">
                <p class="white--text full-width text-uppercase no-select ml-1 mb-0">
                  Cosa preferiresti fare in questa lezione? (lascia vuoto se non hai preferenze)
                </p>
              </v-col>
              <v-col cols="12">
                <v-checkbox color="accent" class="pa-3 white ma-1 rounded" hide-details
                            v-for="pref in currentPrefs" :key="pref._id" :disabled="isPrefDisabled(pref)"
                            :label="pref.name" :value="pref._id" v-model="prefs" />
              </v-col>


            </v-row>
            <v-btn x-large color="accent" rounded class="full-width" @click="requestBookDate()" :loading="loading">PRENOTA!</v-btn>
          </v-col>
        </v-row>
      </v-col>


    </v-row>

    <v-dialog v-model="pausesDialog" max-width="450" dark>
      <v-card>
        <v-card-title class="title accent--text text-uppercase">
          Vuoi dei giorni in più di pausa?
        </v-card-title>
        <v-card-text>
          <p>Puoi acquistare fino ad un massimo di 20 giorni in più di pausa all'interno dell'anno sportivo (dal {{sportYearStart}} al {{sportYearEnd}})</p>
          <h4 class="subtitle-1 text-uppercase accent--text"  v-if="numDaysOfPausesAvailable < 30">Seleziona i giorni extra di cui necessiti</h4>
         <!-- <p v-if="numDaysOfExtraPausesAvailable > 0">Possiedi <strong>{{numDaysOfExtraPausesAvailable}}</strong> giorni extra di pausa abbonamento di cui ne hai usati <strong>{{profile.num_pauses}}</strong></p> -->
          <v-text-field v-if="numDaysOfPausesAvailable < 30" label="N° giorni di pausa" filled rounded v-model="extraDays" type="number" step="1" min="1" max="20" :error="extraDaysError" :error-messages="extraDaysErrorMessages"></v-text-field>
          <p v-else>Non puoi acquistare ulteriori giorni</p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="pausesDialog = false">Annulla</v-btn>
          <v-btn text color="accent" @click="buyPauses()" :disabled="extraDays < 0 || numDaysOfPausesAvailable >= 30 || extraDaysError">Acquista</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="noPauseDialog" max-width="450" dark>
      <v-card>
        <v-card-title class="title accent--text text-uppercase">
          Attenzione
        </v-card-title>
        <v-card-text>
          <p>Non puoi mettere in pausa i tuoi abbonamenti se hai attive delle prenotazioni per delle sedute!</p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="accent" @click="noPauseDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="noBookingDialog" max-width="450" dark>
      <v-card>
        <v-card-title class="title accent--text text-uppercase">
          Attenzione
        </v-card-title>
        <v-card-text>
          <p>Non puoi prenotare una seduta se hai in pausa i tuoi abbonamenti!</p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="accent" @click="noBookingDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import utils from "../utils";
import config from "@/config";
import moment from "moment";
import {seatsTypes, seatsTypesOptions} from "../consts";
import {DateTime} from "luxon";
export default {
  name: "Seats",
  data () {
    return {
      loading : false,
      buySeatsDialog : false,
      numSeatsToBuy : 10,
      calendarType : '',
      date : null,
      hours : [],
      allowed : [],
      pauses : [],
      prefs : [],
      selectedHour : null,
      currentPrefs : [],
      prefsCount : {},
      userSeatsId : null,
      pausesDialog : false,
      noPauseDialog : false,
      noBookingDialog : false,
      extraDays : 1,
    };
  },
  watch : {
    calendarType() {
      this.prefs = [];
      this.selectedHour = null;
      this.date = null;
    },
    date() {
      this.prefs = [];
      this.selectedHour = null;
      this.loadHours();
    }
  },
  computed : {
    ...mapGetters(['token','profile','cms','userSeats','bookings','settings','bookingprefs','validUserSeats','products']),
    extraDaysErrorMessages() {
      const days = utils.toInt(this.extraDays, 0)
      if(days < 1) {
        return ['inserisci un numero maggiore di zero']
      }
      if(days + this.numDaysOfPausesAvailable > 30) {
        return ["non puoi acquistare più di 20 giorni di pausa durante l'anno sportivo"]
      }
      return []
    },
    extraDaysError() {
      return this.extraDaysErrorMessages.length > 0
    },
    validUserSeatsOptions() {

      return this.validUserSeats.map(us => {
        let text = us.seats_types.map(this.getSeatsTypeLabel).join(', ')
        if(us.product_id) {
          const pr = utils.getObjectInArray(this.products,'_id', us.product_id)
          const prTitle = pr.kit ? pr.kit.title.it + " - " + pr.title.it : pr.title.it;
          text = prTitle + ` (${us.seats_types.map(this.getSeatsTypeLabel).join(', ')})`
        }
        return { text , data : us, value : us._id }
      })
    },
    canPauseSubscription() {
      return this.numDaysOfPausesAvailable > this.profile.num_pauses
    },
    sportYearStart() {
      let start = DateTime.now().set({month : this.settings.month_of_sport_year_start, day : this.settings.day_of_sport_year_start})
      return start.toFormat('dd/MM')
    },
    sportYearEnd() {
      let end = DateTime.now().set({month : this.settings.month_of_sport_year_start, day : this.settings.day_of_sport_year_start}).minus({day:1})
      return end.toFormat('dd/MM')
    },
    selectedUserSeat() {
      if(!this.userSeatsId) return null;
      return utils.getObjectInArray(this.validUserSeats, '_id', this.userSeatsId)
    },
    calendarTypeOpts() {
      return seatsTypesOptions.filter(st => {
        if(!this.selectedUserSeat) return false;
        return this.selectedUserSeat.seats_types.includes(st.value)
        /*
        const f = this.validUserSeats.filter(us => us.seats_types.includes(st.value))
        return f.length > 0*/
      })
    },
    noMedicalCert() {
      return !this.profile.medical_cert_expiration || moment(this.profile.medical_cert_expiration).isBefore(moment())
    },
    noMedicalCertMessage() {
      if(!this.profile.medical_cert_expiration) return "Non hai ancora un certificato medico sportivo valido!"
      const dt = DateTime.fromISO(this.profile.medical_cert_expiration)
      if(dt < DateTime.now()) {
        return `Il tuo certificato medico sportivo è scaduto il ${dt.toFormat("dd/MM/yyyy")}!`
      }
      if(dt.minus({day : 7}) < DateTime.now()) {
        return `Il tuo certificato medico sportivo è in scadenza il ${dt.toFormat("dd/MM/yyyy")}!`
      }
      return null
    },
    schedaUrl() {
      return `${config.server}/api/users/${this.profile._id}/scheda?t=${this.token}`;
    },
    typedBookings() {
      return this.bookings ? this.bookings.filter(b => b.type === this.calendarType) : [];
    },
    topGymExpSeats() {
      let f = this.userSeats.filter(item => item.seats_types.includes('topgymexperience') && item.available > item.used)
      return f.length > 0 ? f[0] : null;
    },
    extendedDate() {
      if(!this.date) return ''
      moment.locale('it')
      let text = moment(this.date, 'YYYY-MM-DD').format("dddd D MMMM")
      return text.replace(/^./, text[0].toUpperCase());
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },
    maxDate() {
      return moment().add(30, 'days').format('YYYY-MM-DD');
    },
    numDaysOfPausesAvailable() {
      let num = this.settings.max_days_of_subscription_pause;
      for(let i = 0; i < this.pauses.length; i++) {
        const pause = this.pauses[i];
        num += pause.num_days
      }
      return num;
    },
    numDaysOfExtraPausesAvailable() {
      let num = 0;
      for(let i = 0; i < this.pauses.length; i++) {
        const pause = this.pauses[i];
        num += pause.num_days
      }
      return num;
    }
  },
  methods : {
    ...mapActions(['refreshCollection','get','load','insert','remove','loadSeats','loadProfile','profileUpdate',
      'loadBookings','sendSuccess','sendError','requestConfirm','loadSettings','saveUserCart','setPauseDaysToCart']),
    getProductName(id) {
      const p = utils.getObjectInArray(this.products, '_id', id)
      if(!p) return null
      if(p.kit) {
        return p.kit.title.it + " - " + p.title.it
      }
      return p.title.it
    },
    isPrefDisabled(pref) {
      if(!pref.limit) return false;
      const used = this.prefsCount[pref._id] || 0;
      return used >= pref.limit
    },
    async onSelectedHour() {
      const filter = { query : { hour: this.selectedHour, type : this.calendarType, date : DateTime.fromISO(this.date).toISODate() }, limit : 0, page : 1, sort : 'name', order : 'ASC' }
      try {
        const res = await this.load({collection:'bookingprefs', filter})
        this.currentPrefs = res.data;
        this.prefsCount = res.prefs
      } catch (e) {
        this.currentPrefs = [];
        this.prefsCount = {}
      }

    },
    getSeatsTypeLabel( st ) {
      const opt = utils.getObjectInArray(seatsTypesOptions, 'value', st)
      return opt ? opt.text : st;
    },
    getSeatsTypeColor( st ) {
      const opt = utils.getObjectInArray(seatsTypesOptions, 'value', st)
      return opt ? opt.color : '';
    },
    allowedDates( val ) {
      return this.allowed.includes(val)
    },
    getLbl( caltype ) {
      let f = this.calendarTypeOpts.filter(i => i.value === caltype)
      return f.length ? f[0].text : '-'
    },
    isBooked( date ) {
      let filter = this.typedBookings.filter(item => {
        let test = moment(item.date).format('YYYY-MM-DD')
        return test === date;
      })
      return filter.length > 0
    },
    formatDate(date) {
      return moment(date).format("dddd D MMMM YYYY")
    },
    formatDateTime(date) {
      return moment(date).format("dddd D MMMM YYYY [alle] HH:mm")
    },
    async loadPauses() {
      try {
        let { data } = await this.get({collection : 'mypauses' })
        this.pauses = data.filter(p => {
          const from = DateTime.fromISO(p.from)
          const to = DateTime.fromISO(p.to)
          return DateTime.now() > from && DateTime.now() < to
        })
      } catch (e) {
        console.log(e)
      }

    },
    async loadHolidays() {
      try {
        let { data } = await this.get({collection : 'holidays', id: `check/${this.today}/${this.maxDate}`})
        this.allowed = data
      } catch (e) {
        console.log(e)
      }

    },
    async loadHours() {
      if(!this.date) {
        this.hours = [];
        return
      }
      try {
        moment.locale('it')
        let day = moment(this.date, 'YYYY-MM-DD').weekday() + 1
        let filter = { query : { day , type : this.calendarType }, limit : 0, page : 1, sort : 'time', order : 'ASC' }
        let { data } = await this.load({collection : 'hours', filter })
        this.hours = data
      } catch (e) {
        console.log(e)
      }
    },
    requestBookDate() {
      if(this.profile.pause_subscription) {
        this.noBookingDialog = true
        return
      }
      const hour = utils.getObjectInArray(this.hours, '_id', this.selectedHour)
      let date = moment(`${this.date} ${hour.time}`,'YYYY-MM-DD HH:mm')
      let typeLbl = hour.type === 'corsi' ? hour.name : this.getLbl(hour.type);
      let message = `Sei sicuro di voler prenotare una seduta di ${typeLbl} il ${date.format("dddd D MMMM [alle] HH:mm")}?`
      this.requestConfirm({title:"Richiesta conferma", message, callback : (ok) => {
        if(ok) this.bookDate();
        }
      });
    },
    async bookDate() {

      if(!this.date || !this.selectedHour) return;
      this.loading = true

      const hour = utils.getObjectInArray(this.hours, '_id', this.selectedHour)

      let data = {
        user_seat_id : this.userSeatsId,
        user_id : this.profile._id,
        calendar_hour_id : this.selectedHour,
        date : moment(`${this.date} ${hour.time}`,'YYYY-MM-DD HH:mm').toJSON(),
        type : hour.type,
        prefs_ids : this.prefs,
      }
      try {
        await this.insert({collection: 'booking', data})
        this.sendSuccess({message : 'Prenotazione effettuata!'})
        this.loadSeats().then(()=>{});
        this.refreshBookings().then(()=>{});
        this.selectedHour = null;
        this.date = null;
      } catch (err) {
        let message = err && err.message ? err.message : "Ci scusiamo, c'è stato un errore, perfavore riprova più tardi";
        this.sendError({message})
      }
      this.loading = false;
    },
    requestCancelBook( book ) {
      let date = moment(book.date)
      let message = `Sei sicuro di voler cancellare la prenotazione di ${date.format("dddd D MMMM [alle] HH:mm")}?`
      this.requestConfirm({title:"Richiesta conferma", message, callback : (ok) => {
          if(ok) this.cancelBook(book._id);
        }
      });
    },
    async cancelBook( id ) {
      try {
        await this.remove({collection:'booking', id})
        this.sendSuccess({message : 'Prenotazione cancellata!'})
        this.loadSeats().then(()=>{});
        this.refreshBookings().then(()=>{});
      } catch (err) {
        let message = err && err.message ? err.message : "Ciu scusiamo, c'è stato un errore, perfavore riprova più tardi";
        this.sendError({message})
      }
    },
    async refreshBookings() {
      await this.loadBookings({query : { from : this.today }})
    },
    async setSubscriptionPaused(value) {
      if(value && this.bookings.length > 0) {
        this.noPauseDialog = true
        return
      }
      let data = {...this.profile};
      data.pause_subscription = value
      try {
        await this.profileUpdate({ token : this.token, body : data })
        await this.loadProfile()
      } catch (e) {

      }
    },
    buyPauses() {
      const days = utils.toInt(this.extraDays, 0)
      this.setPauseDaysToCart({days});
      this.saveUserCart();
      this.$router.push('/cart')
    },
    openPauseDialog() {
      this.pausesDialog = true
    }
  },
  async mounted() {
    await this.loadSettings();
    await this.loadSeats()
    await this.refreshBookings();
    await this.loadHolidays();
    await this.loadPauses();
    await this.refreshCollection({collection:'bookingprefs'})
    await this.refreshCollection({collection:'products'})
  }
}
</script>

<style scoped>

</style>
